import Web3Modal from 'web3modal'
import { ethers } from 'ethers'

import Stablecoin from 'lib/Stablecoin.json'
import Marketplace from 'lib/Marketplace.json'
import { STABLECOIN_ADDRESS, MARKETPLACE_ADDRESS } from 'app/app.config'
import { Marketplace as IMarketplace } from 'typechain'
import { Stablecoin as IStablecoin } from 'typechain'

export const getProvider = async () => {
  const web3Modal = new Web3Modal({
    providerOptions: {},
  })
  const instance = await web3Modal.connect()
  return new ethers.providers.Web3Provider(instance)
}

export const getSigner = async () => {
  const provider = await getProvider()
  return provider.getSigner()
}

export const getStablecoin = async () => {
  const signer = await getSigner()

  return new ethers.Contract(
    STABLECOIN_ADDRESS,
    Stablecoin.abi,
    signer,
  ) as IStablecoin
}

export const getMarketplace = async () => {
  const signer = await getSigner()

  return new ethers.Contract(
    MARKETPLACE_ADDRESS,
    Marketplace.abi,
    signer,
  ) as IMarketplace
}
