import { AxiosResponse } from 'axios'

import {
  SMART_BONDS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  TERM_SHEETS_ENDPOINT,
} from 'app/server.config'
import { request } from 'app/server.http'
import { Currency, Order, Payout, SmartBond, Termsheet } from './asset.screen'

export const fetchTermSheets = (): Promise<
  AxiosResponse<{ payload: Termsheet[] }>
> => {
  return request({
    method: 'get',
    url: TERM_SHEETS_ENDPOINT,
  })
}

export const fetchCurrencies = (): Promise<
  AxiosResponse<{ payload: Currency[] }>
> => {
  return request({
    method: 'get',
    url: CURRENCIES_ENDPOINT,
  })
}

export const fetchSmartBond = ({
  id,
}: {
  id: number
}): Promise<AxiosResponse<{ payload: SmartBond }>> => {
  return request({
    method: 'get',
    url: `${SMART_BONDS_ENDPOINT}/${id}`,
  })
}

export const fetchSmartBonds = (): Promise<
  AxiosResponse<{ payload: SmartBond[] }>
> => {
  return request({
    method: 'get',
    url: SMART_BONDS_ENDPOINT,
  })
}

export const fetchOrders = ({
  id,
}: {
  id: number
}): Promise<AxiosResponse<{ payload: Order[] }>> => {
  return request({
    method: 'get',
    url: `${SMART_BONDS_ENDPOINT}/${id}/orders`,
  })
}

export const fetchPayouts = ({
  id,
}: {
  id: number
}): Promise<AxiosResponse<{ payload: Payout[] }>> => {
  return request({
    method: 'get',
    url: `${SMART_BONDS_ENDPOINT}/${id}/payouts`,
  })
}
