import { AxiosResponse } from 'axios'
import { ethers } from 'ethers'

import { request } from 'app/server.http'
import { AUTHENTICATE_ENDPOINT, SECRET_ENDPOINT } from 'app/server.config'
import { getSigner } from 'lib/ethereum'
import { AuthenticateResponse } from './user.types'

export const fetchEtherBalance = async () => {
  const signer = await getSigner()
  const balance = await signer.getBalance()

  return Math.round(parseFloat(ethers.utils.formatEther(balance)) * 100) / 100
}

export const authenticate = (
  address: string,
  signature: string,
): Promise<AxiosResponse<AuthenticateResponse>> => {
  return request({
    method: 'post',
    url: AUTHENTICATE_ENDPOINT,
    data: { address, signature },
  })
}

export const fetchAuthenticationSecret = (
  signer: string,
): Promise<AxiosResponse<{ signer: string; secret: string }>> => {
  return request({
    method: 'get',
    url: `${SECRET_ENDPOINT}/${signer}`,
  })
}
