// @ts-nocheck
export const MARKETPLACE_ADDRESS = window.__ENV__
  ? window.__ENV__.REACT_APP_MARKETPLACE_ADDRESS
  : process.env.REACT_APP_MARKETPLACE_ADDRESS

export const STABLECOIN_ADDRESS = window.__ENV__
  ? window.__ENV__.REACT_APP_STABLECOIN_ADDRESS
  : process.env.REACT_APP_STABLECOIN_ADDRESS

export const APP_DATE_FORMAT = 'DD. MMM. YYYY'
