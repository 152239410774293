import React from 'react'
import { LoginIcon } from '@heroicons/react/outline'

import { useAppDispatch } from 'app/redux.hooks'
import { getSigner } from 'lib/ethereum'
import Logo from 'assets/images/logo.png'
import * as userRx from './user.redux'
import { fetchAuthenticationSecret } from './user.service'
import { ethers } from 'ethers'

const SignInScreen: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const authenticate = async () => {
    const signer = await getSigner()
    const address = await signer.getAddress()
    const response = await fetchAuthenticationSecret(address)
    const signature = await signer.signMessage(
      ethers.utils.toUtf8Bytes(response.data.secret),
    )
    await dispatch(userRx.authenticate(signature))
  }

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in with your wallet
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <a
              href="#"
              className="font-medium text-blue-700 hover:text-blue-500"
            >
              learn how to create a wallet
            </a>
          </p>
        </div>

        <div>
          <button
            onClick={authenticate}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ryskex hover:bg-ryskex/95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LoginIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
            Sign in
          </button>
        </div>
      </div>
    </div>
  )
}

export default SignInScreen
