import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

import { getMarketplace } from 'lib/ethereum'
import TxProgressComponent from './tx-progress.component'
import { Order, SmartBond } from './asset.screen'
import { ethers } from 'ethers'
import NumberFormat from 'react-number-format'

export interface Config {
  isOpen: boolean
  params?: {
    smartBond: SmartBond
    order: Order
  }
}

interface Props {
  config: Config
  close: () => void
}

const OrderFillComponent: React.FunctionComponent<Props> = (props) => {
  const [amount, setAmount] = useState('')
  const [txHash, setTxHash] = useState('')
  const [progress, setProgress] = useState(0)

  const i_amount = parseInt(amount)

  const orderPlace = async () => {
    if (!props.config.params) return

    setProgress(1)

    const marketplace = await getMarketplace()
    const response = await marketplace.orderFill(
      props.config.params.smartBond.id,
      props.config.params.order.id,
      ethers.utils.parseUnits(
        amount,
        props.config.params.smartBond.currency.decimals,
      ),
    )

    setProgress(64)

    const tx = await response.wait()

    setProgress(100)
    setTxHash(tx.transactionHash)
  }

  useEffect(() => {
    setAmount('1000000')
    setTxHash('')
    setProgress(0)
  }, [props.config])

  let information = ''
  if (props.config.params?.order.side === 1) {
    information = `By confirming you agree to sell ${Intl.NumberFormat(
      'en',
    ).format(i_amount)} collateralized risk shares to ${
      props.config.params.order.signer.name
    }, paying a premium of ${Intl.NumberFormat('en').format(
      ((props.config.params?.order.premium || 0) * i_amount) / 10000,
    )} ${props.config.params?.smartBond?.currency.symbol}`
  } else if (props.config.params?.order.side === 0) {
    information = `By confirming you agree to receive a premium of ${Intl.NumberFormat(
      'en',
    ).format(((props.config.params?.order.premium || 0) * i_amount) / 10000)} ${
      props.config.params.smartBond.currency.symbol
    } from ${
      props.config.params.order.signer.name
    } in exchange for ${Intl.NumberFormat('en').format(
      i_amount,
    )} collateralized risk shares. Collateral will be held in escrow by the smart contract and will thus be transferred from your wallet.`
  }

  return (
    <Transition.Root show={props.config.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={props.close}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-ryskex p-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Fill an order
                          </Dialog.Title>
                          <p className="text-sm text-gray-100">
                            Advance to fill a buy or sell order from the risk
                            order book.
                          </p>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-100 hover:text-gray-200"
                            onClick={props.close}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 p-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Amount
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <NumberFormat
                            value={amount}
                            thousandSeparator={true}
                            onValueChange={(values) => {
                              const { value } = values
                              setAmount(value)
                            }}
                            className="flex-1 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 p-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-900">
                          Premium
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <p className="text-sm">
                          {Intl.NumberFormat('en').format(
                            props.config.params?.order.premium || 0,
                          )}{' '}
                          BPS
                        </p>
                      </div>
                    </div>

                    <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 p-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-900">
                          Information
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <p className="text-sm">{information}</p>
                      </div>
                    </div>
                  </div>

                  <div className="p-6">
                    <TxProgressComponent progress={progress} txHash={txHash} />
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 p-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={props.close}
                      >
                        Close
                      </button>
                      <button
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault()
                          orderPlace()
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-ryskex py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-ryskex/95 focus:outline-none focus:ring-2 focus:ring-ryskex/95 focus:ring-offset-2"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default OrderFillComponent
