import dayjs from 'dayjs'
import { ethers } from 'ethers'
import React, { useEffect, useState } from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useNavigate } from 'react-router'
import { routes } from 'routes'
import Geography from 'assets/images/greentrees/project-area.png'
import US_Wildfire_II from 'assets/images/greentrees/US_Wildfire_II.png'
import US_Flood_II from 'assets/images/greentrees/US_Flood_II.png'
import US_Hurricane_I from 'assets/images/greentrees/US_Hurricane_I.png'
import US_Hurricane_II from 'assets/images/greentrees/US_Hurricane_II.png'
import US_Forest_Disease_I from 'assets/images/greentrees/US_Forest_Disease_I.png'
//import US_Earthquake_I from 'assets/images/greentrees/US_Earthquake_I.png'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const features = [
  {
    name: 'Categories',
    description: 'ESG Investing, Climate Change, Carbon Credits',
  },
  {
    name: 'Geographical Exposure',
    description: 'U.S. - Arkansas, Louisiana, Mississippi',
  },
  {
    name: 'Perils',
    description: 'Hurricane, Fire, Diseases & Pests and Flood',
  },

  {
    name: 'Risk Level',
    description: 'Very low, but with a significant multiple',
  },
  {
    name: 'Trigger Type',
    description: 'Parametric - Single Trigger',
  },
  {
    name: 'Detailed Risk Assessment',
    description: (
      <a
        onClick={() =>
          window.open(
            'https://app.box.com/s/z4t3iblofu5vq164yynoqjhiib7gw7v4',
            '_blank',
          )
        }
        className="cursor-pointer text-sm font-medium text-blue-600 hover:text-blue-700 sm:block"
      >
        Download
      </a>
    ),
  },
]

const products = [
  {
    id: 3,
    name: 'Hurricane',
    description:
      'While hurricanes frequently strike the south of Louisiana, they detriorate quickly, before reaching the project area. In order to destroy these trees, a Cat IV hurricane would have to strike the area and stay at least that strong, travelling north.',
    href: '#',
    price: 'Trajectories in the past 140 years',
    status: '',
    step: 2,
    date: 'March 24, 2021',
    datetime: '2021-03-24',
    address: [
      'National Hurricane Center (NHC)',
      'National Oceanic and Atmospheric Administration (NOAA)',
      'Zoom.earth',
    ],
    mitigation: [
      'Buffered Deductible',
      'Geographic diversification',
      'Fully grown trees',
      'Natural deterioration of windspeeds',
    ],
    imageSrc: US_Hurricane_I,
    imageAlt: 'Insulated bottle with white base and black snap lid.',
  },
  {
    id: 1,
    name: 'Wildfire - Per U.S. County',
    description:
      'There has not been a single wildfire in the project area, in the past 42 years. Due to geographic diversity of the landowners, wildfire is very unlikely to trigger a payout.',
    href: '#',
    price: 'U.S. County heat map',
    status: '',
    step: 0,
    date: 'March 24, 2021',
    datetime: '2021-03-24',
    address: [
      'National Centers for Environmental Information (NCEI)',
      'National Oceanic and Atmospheric Administration (NOAA)',
    ],
    mitigation: [
      'Buffered Deductible',
      'Geographic diversification',
      'Below ground credits unaffected',
    ],
    imageSrc: US_Wildfire_II,
    imageAlt: 'Insulated bottle with white base and black snap lid.',
  },
  {
    id: 2,
    name: 'Flood',
    description:
      'There have been floods in the project area, but as the water moves through the forests, it does not destroy enough trees. Due to geographic diversity of the landowners, flood is very unlikely to trigger a payout.',
    href: '#',
    price: 'U.S. County heat map',
    status: '',
    step: 0,
    date: 'March 24, 2021',
    datetime: '2021-03-24',
    address: [
      'National Centers for Environmental Information (NCEI)',
      'National Oceanic and Atmospheric Administration (NOAA)',
    ],
    mitigation: [
      'Buffered Deductible',
      'Geographic diversification',
      'Nature of terrain prevents water from staying for weeks',
    ],
    imageSrc: US_Flood_II,
    imageAlt: 'Insulated bottle with white base and black snap lid.',
  },

  {
    id: 4,
    name: 'Diseases & Pests',
    description:
      'The are no current diseases or pests affecting the project area. A recent study shows yellow pine beetle affected areas nearby, however these only yellow pines, which are not part of the reforested land.',
    href: '#',
    price: 'Recent occurrences around the project area',
    status: '',
    step: 2,
    date: 'March 24, 2021',
    datetime: '2021-03-24',
    address: ['United States Department of Agriculture', 'U.S. Forest Service'],
    mitigation: [
      'Buffered Deductible',
      'Geographic diversification',
      'Diversity of species',
    ],
    imageSrc: US_Forest_Disease_I,
    imageAlt: 'Insulated bottle with white base and black snap lid.',
  },
  /*{
    id: 5,
    name: 'Earthquake',
    description:
      'The only U.S. State in this project, that is frequently hit by earthquakes, is Arkansas, but the affected area is way north of the project area. In addition, the highest ever recorded earthquake there is of magnitude 4.7, which is not sufficient to destroy trees.',
    href: '#',
    price: 'Past occurrences around the project area',
    status: '',
    step: 1,
    date: 'March 24, 2021',
    datetime: '2021-03-24',
    address: ['United States Geological Survey'],
    mitigation: [
      'Buffered Deductible',
      'Geographic diversification',
      'Generally safe area',
    ],
    imageSrc: US_Earthquake_I,
    imageAlt: 'Insulated bottle with white base and black snap lid.',
  },*/
]

const worst_case = [
  {
    id: 2,
    name: 'Hurricane',
    description:
      'A hurricane that makes landfall in Louisiana at Cat V+ and strikes the project area at least as Cat IV with a very exact trajectory to hit all trees.',
    href: '#',
    price: 'Trajectories possible in the future',
    status: '',
    step: 0,
    date: 'March 24, 2021',
    datetime: '2021-03-24',
    address: [
      'National Centers for Environmental Information (NCEI)',
      'National Oceanic and Atmospheric Administration (NOAA)',
    ],
    mitigation: [
      'Buffered Deductible',
      'Geographic diversification',
      'Natural deterioration of windspeeds',
      'Based on a prediction 30 years into the future',
    ],
    imageSrc: US_Hurricane_II,
    imageAlt: 'Insulated bottle with white base and black snap lid.',
  },
]

const DashboardScreen: React.FunctionComponent = () => {
  const navigate = useNavigate()

  return (
    <div>
      <div className="bg-white">
        <div aria-hidden="true" className="relative">
          <img
            src={Geography}
            alt=""
            className="h-96 w-full object-cover object-center"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-white" />
        </div>

        <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              GreenTrees (Carbon Credit Protection)
            </h2>
            <p className="mt-4 text-gray-500">
              The GreenTrees (GT) project is a reforestation carbon credit
              project, located around the border of Louisiana, Mississippi and
              Arkansas. It is exposed to the risk of reversal, due to the
              destruction of grown trees, to an extent, where more registered
              credits are destroyed, than fresh ones are generated.
            </p>
          </div>

          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="mx-auto max-w-2xl pt-16 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
            <div className="flex sm:items-baseline sm:space-x-4">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Perils #5
              </h1>
              <a
                onClick={() =>
                  window.open(
                    'https://app.box.com/s/z4t3iblofu5vq164yynoqjhiib7gw7v4',
                    '_blank',
                  )
                }
                className="cursor-pointer text-sm font-medium text-blue-600 hover:text-blue-700 sm:block"
              >
                View brochure
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
            <p className="text-sm text-gray-600">
              Updated at{' '}
              <time dateTime="2021-03-22" className="font-medium text-gray-900">
                Jan 22, 2023
              </time>
            </p>
          </div>

          {/* Products */}
          <div className="mt-6">
            <h2 className="sr-only">Products purchased</h2>

            <div className="space-y-8">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="border-t border-b border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                >
                  <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                    <div className="sm:flex lg:col-span-7">
                      <div className="aspect-w-1 aspect-h-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40 hover:border-2">
                        <img
                          src={product.imageSrc}
                          onClick={() =>
                            window.open(product.imageSrc, '_blank')
                          }
                          alt={product.imageAlt}
                          className="h-full w-full object-cover object-center sm:h-full sm:w-full cursor-pointer"
                        />
                      </div>

                      <div className="mt-6 sm:mt-0 sm:ml-6">
                        <h3 className="text-base font-medium text-gray-900">
                          <a href={product.href}>{product.name}</a>
                        </h3>
                        <p className="mt-2 text-sm font-medium text-gray-900">
                          {product.price}
                        </p>
                        <p className="mt-3 text-sm text-gray-500">
                          {product.description}
                        </p>
                      </div>
                    </div>

                    <div className="mt-6 lg:col-span-5 lg:mt-0">
                      <dl className="grid grid-cols-2 gap-x-6 text-sm">
                        <div>
                          <dt className="font-medium text-gray-900">
                            Data Sources
                          </dt>
                          <dd className="mt-3 text-gray-500">
                            {product.address.map((s) => (
                              <p>- {s}</p>
                            ))}
                          </dd>
                        </div>
                        <div>
                          <dt className="font-medium text-gray-900">
                            Risk Reducing Factors
                          </dt>
                          <dd className="mt-3 text-gray-500">
                            {product.mitigation.map((s) => (
                              <p>- {s}</p>
                            ))}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8">
                    <p className="text-sm font-medium text-gray-900">
                      Risk classification
                    </p>
                    <div className="mt-6" aria-hidden="true">
                      <div className="overflow-hidden rounded-full bg-gray-200">
                        <div
                          className="h-2 rounded-full bg-ryskex"
                          style={{
                            width: `calc((${product.step} * 2 + 1) / 64 * 100%)`,
                          }}
                        />
                      </div>
                      <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                        <div className="text-ryskex">Very low</div>
                        <div
                          className={classNames(
                            product.step > 0 ? 'text-ryskex' : '',
                            'text-center',
                          )}
                        >
                          Low
                        </div>
                        <div
                          className={classNames(
                            product.step > 1 ? 'text-ryskex' : '',
                            'text-center',
                          )}
                        >
                          Medium
                        </div>
                        <div
                          className={classNames(
                            product.step > 2 ? 'text-ryskex' : '',
                            'text-right',
                          )}
                        >
                          High
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-2 px-4 pt-16 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
            <div className="flex sm:items-baseline sm:space-x-4">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Worst Case Scenario #1
              </h1>
              <a
                onClick={() =>
                  window.open(
                    'https://app.box.com/s/z4t3iblofu5vq164yynoqjhiib7gw7v4',
                    '_blank',
                  )
                }
                className="cursor-pointer text-sm font-medium text-blue-600 hover:text-blue-700 sm:block"
              >
                View brochure
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
            <p className="text-sm text-gray-600">
              Updated at{' '}
              <time dateTime="2021-03-22" className="font-medium text-gray-900">
                Jan 22, 2023
              </time>
            </p>
            <a
              href="#"
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:hidden"
            >
              View risk analysis
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>

          {/* Products */}
          <div className="mt-6">
            <h2 className="sr-only">Products purchased</h2>

            <div className="space-y-8">
              {worst_case.map((product) => (
                <div
                  key={product.id}
                  className="border-t border-b border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                >
                  <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                    <div className="sm:flex lg:col-span-7">
                      <div className="aspect-w-1 aspect-h-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40 hover:border-2">
                        <img
                          src={product.imageSrc}
                          onClick={() =>
                            window.open(product.imageSrc, '_blank')
                          }
                          alt={product.imageAlt}
                          className="h-full w-full object-cover object-center sm:h-full sm:w-full cursor-pointer"
                        />
                      </div>

                      <div className="mt-6 sm:mt-0 sm:ml-6">
                        <h3 className="text-base font-medium text-gray-900">
                          <a href={product.href}>{product.name}</a>
                        </h3>
                        <p className="mt-2 text-sm font-medium text-gray-900">
                          {product.price}
                        </p>
                        <p className="mt-3 text-sm text-gray-500">
                          {product.description}
                        </p>
                      </div>
                    </div>

                    <div className="mt-6 lg:col-span-5 lg:mt-0">
                      <dl className="grid grid-cols-2 gap-x-6 text-sm">
                        <div>
                          <dt className="font-medium text-gray-900">
                            Data Sources
                          </dt>
                          <dd className="mt-3 text-gray-500">
                            {product.address.map((s) => (
                              <p>- {s}</p>
                            ))}
                          </dd>
                        </div>
                        <div>
                          <dt className="font-medium text-gray-900">
                            Risk Reducing Factors
                          </dt>
                          <dd className="mt-3 text-gray-500">
                            {product.mitigation.map((s) => (
                              <p>- {s}</p>
                            ))}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8">
                    <p className="text-sm font-medium text-gray-900">
                      Risk classification
                    </p>
                    <div className="mt-6" aria-hidden="true">
                      <div className="overflow-hidden rounded-full bg-gray-200">
                        <div
                          className="h-2 rounded-full bg-ryskex"
                          style={{
                            width: `calc((${product.step} * 2 + 1) / 64 * 100%)`,
                          }}
                        />
                      </div>
                      <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                        <div className="text-ryskex">Very low</div>
                        <div
                          className={classNames(
                            product.step > 0 ? 'text-ryskex' : '',
                            'text-center',
                          )}
                        >
                          Low
                        </div>
                        <div
                          className={classNames(
                            product.step > 1 ? 'text-ryskex' : '',
                            'text-center',
                          )}
                        >
                          Medium
                        </div>
                        <div
                          className={classNames(
                            product.step > 2 ? 'text-ryskex' : '',
                            'text-right',
                          )}
                        >
                          High
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Billing */}
          <div className="mt-16">
            <div className="bg-gray-100 py-6 px-4 sm:rounded-lg sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
              <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
                <div>
                  <dt className="font-medium text-gray-900">Deal Initiator</dt>
                  <dd className="mt-3 text-gray-500">
                    <span className="block">
                      ACRE Investment Management, LLC
                    </span>
                    <span className="block">4243 Jackson Street</span>
                    <span className="block">The Plains, Virginia</span>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">
                    Calculation Agents
                  </dt>
                  <dd className="mt-3 text-gray-500">
                    <span className="block">RYSKEX, Inc.</span>
                    <span className="block">ACRE IO, LLC.</span>
                  </dd>
                </div>
              </dl>

              <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-5 lg:mt-0">
                <div className="flex items-center justify-between pb-4">
                  <dt className="text-gray-600">Probability of Attachment</dt>
                  <dd className="font-medium text-gray-900">0.5 %</dd>
                </div>
                <div className="flex items-center justify-between py-4">
                  <dt className="text-gray-600">Probability of Exhaustion</dt>
                  <dd className="font-medium text-gray-900">0.1 %</dd>
                </div>
                <div className="flex items-center justify-between py-4">
                  <dt className="text-gray-600">Expected Loss</dt>
                  <dd className="font-medium text-gray-900">0.4 %</dd>
                </div>
                <div className="flex items-center justify-between pt-4">
                  <dt className="font-medium text-gray-900">
                    Risk Premium (recomm.)
                  </dt>
                  <dd className="font-medium text-indigo-600">5 %</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardScreen
