import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/redux.store'

import { CoreState } from './core.types'

const initialState: CoreState = {
  appVersion: '',
}

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setAppVersion: (state, action: PayloadAction<string>) => {
      state.appVersion = action.payload
    },
  },
})

export const { setAppVersion } = coreSlice.actions

export const selectAppVersion = (state: RootState) => state.core.appVersion

export default coreSlice.reducer
