import React, { useEffect, useState } from 'react'

import { useAppSelector } from 'app/redux.hooks'
import * as userRx from 'screens/user/user.redux'
import { ethers } from 'ethers'
import { getStablecoin } from 'lib/ethereum'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { MARKETPLACE_ADDRESS, STABLECOIN_ADDRESS } from 'app/app.config'

const UserProfileScreen: React.FunctionComponent = () => {
  const [allowance, setAllowance] = useState<Boolean>()
  const signerAddress = useAppSelector(userRx.selectSignerAddress) || ''

  const grantAllowance = async () => {
    const stablecoin = await getStablecoin()
    await stablecoin.approve(
      MARKETPLACE_ADDRESS,
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    )
  }

  const revokeAllowance = async () => {
    const stablecoin = await getStablecoin()
    await stablecoin.approve(MARKETPLACE_ADDRESS, 0)
  }

  const getAllowance = async () => {
    const stablecoin = await getStablecoin()
    const response = await stablecoin.allowance(
      signerAddress,
      MARKETPLACE_ADDRESS,
    )

    if (
      response.eq(
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      )
    ) {
      setAllowance(true)
    } else {
      setAllowance(false)
    }
  }

  const refresh = () => {
    getAllowance()
  }

  useEffect(() => {
    refresh()
  }, [])

  const currencies = [
    {
      name: 'RYSCoin (RYC)',
      title: STABLECOIN_ADDRESS,
      imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    },
  ]

  return (
    <div className="m-4">
      <div className="max-w-8xl mx-auto">
        <div className="md:flex md:items-center md:justify-between md:space-x-5">
          <div className="flex items-center space-x-5">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Stablecoin allowance
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Grants or revokes the marketplace access to a user's funds
              </p>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button
              type="button"
              onClick={refresh}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ryskex"
            >
              Refresh
            </button>
          </div>
        </div>

        <ul role="list" className="mt-4 grid grid-cols-2 gap-6">
          {currencies.map((currency) => (
            <li
              key={currency.title}
              className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">
                      {currency.name}
                    </h3>
                    {allowance === true ? (
                      <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                        Granted
                      </span>
                    ) : null}
                    {allowance === false ? (
                      <span className="flex-shrink-0 inline-block px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full">
                        Denied
                      </span>
                    ) : null}
                  </div>
                  <p className="mt-1 text-gray-500 text-sm truncate">
                    {currency.title}
                  </p>
                </div>
                <img
                  className="w-14 h-14 rounded-full flex-shrink-0"
                  src={currency.imageUrl}
                  alt=""
                />
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="w-0 flex-1 flex">
                    <a
                      href="#"
                      onClick={revokeAllowance}
                      className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                      <XIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Revoke</span>
                    </a>
                  </div>
                  <div className="-ml-px w-0 flex-1 flex">
                    <a
                      href="#"
                      onClick={grantAllowance}
                      className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                    >
                      <CheckIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Grant</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default UserProfileScreen
