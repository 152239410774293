import React from 'react'

interface Props {
  progress: number
  txHash?: string
}

const TxProgressComponent: React.FunctionComponent<Props> = (props) => {
  const { progress, txHash } = props

  let status = ''
  if (progress === 0) status = 'Waiting for confirmation...'
  if (progress > 0) status = 'Waiting for transaction...'
  if (progress === 100) status = 'Tx mined with hash ' + txHash

  return (
    <div>
      <p className="text-sm font-medium text-gray-500">{status}</p>
      <div className="mt-6" aria-hidden="true">
        <div className="bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-1.5 bg-ryskex rounded-full"
            style={{ width: progress + '%' }}
          />
        </div>
        <div className="sm:grid grid-cols-4 text-sm font-medium mt-6">
          <div
            className={`text-${
              progress >= 1 ? 'ryskex' : 'gray-500'
            } font-normal`}
          >
            Initiated
          </div>
          <div
            className={`text-center text-${
              progress >= 64 ? 'ryskex' : 'gray-500'
            } font-normal`}
          >
            Signed
          </div>
          <div
            className={`text-center text-${
              progress >= 64 ? 'ryskex' : 'gray-500'
            } font-normal`}
          >
            Transmitted
          </div>
          <div
            className={`text-right text-${
              progress === 100 ? 'ryskex' : 'gray-500'
            } font-normal`}
          >
            Mined
          </div>
        </div>
      </div>
    </div>
  )
}

export default TxProgressComponent
