import React, { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useIMask } from 'react-imask'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'

import { getMarketplace } from 'lib/ethereum'
import TxProgressComponent from './tx-progress.component'
import { useNavigate } from 'react-router'
import { ethers } from 'ethers'
import { fetchCurrencies, fetchTermSheets } from './asset.service'
import { Currency, Termsheet } from './asset.screen'

const CreateScreen: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [txHash, setTxHash] = useState('')
  const [progress, setProgress] = useState(0)
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [termSheets, setTermSheets] = useState<Termsheet[]>([])
  const [currency, setCurrency] = useState<Currency>()
  const [termsheet, setTermsheet] = useState<Termsheet>()
  const [inception, setInception] = useState(
    dayjs().add(1, 'month').startOf('day').toDate(),
  )
  const [maturity, setMaturity] = useState(
    dayjs(inception).add(1, 'year').subtract(1, 'day').endOf('day').toDate(),
  )

  const { ref: amountRef, typedValue: amount } = useIMask({
    mask: Number,
    thousandsSeparator: ',',
  })

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const getCurrencies = async () => {
    const {
      data: { payload },
    } = await fetchCurrencies()

    setCurrencies(payload)
  }

  const getTermSheets = async () => {
    const {
      data: { payload },
    } = await fetchTermSheets()

    setTermSheets(payload)
  }

  useEffect(() => {
    getCurrencies()
    getTermSheets()
  }, [])

  useEffect(() => {
    setCurrency(currencies[0])
    setTermsheet(termSheets[0])
  }, [currencies, termSheets])

  const submit = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (!termsheet || !currency) return

    setProgress(1)

    const marketplace = await getMarketplace()

    const response = await marketplace.issue(
      termsheet.hash,
      currency.address,
      ethers.utils.parseUnits(amount.toString(), currency.decimals),
      dayjs(inception).unix(),
      dayjs(maturity).unix(),
    )

    setProgress(64)

    const tx = await response.wait()

    setProgress(100)
    setTxHash(tx.transactionHash)
  }

  return (
    <div className="max-w-4xl mx-auto py-8">
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Issue a new Smart Bond
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                This information will be displayed on the public marketplace, to
                attract investors.
              </p>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Termsheet
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-1">
                  <Listbox value={termsheet} onChange={setTermsheet}>
                    {({ open }) => (
                      <>
                        <div className="mt-1 relative">
                          <Listbox.Button className="h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="block truncate">
                              {termsheet?.title}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {termSheets.map((termSheet) => (
                                <Listbox.Option
                                  key={termSheet.hash}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-blue-600'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9',
                                    )
                                  }
                                  value={termSheet}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate',
                                        )}
                                      >
                                        {termSheet.title}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-blue-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  <p className="mt-2 text-gray-600 text-sm">
                    The termsheet specifies the conditions and scales for a
                    payout.{' '}
                    <a
                      className="text-blue-500"
                      href={termsheet?.uri}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>{' '}
                    it to understand the modelled risk.
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Currency (ERC20)
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-1">
                  <Listbox value={currency} onChange={setCurrency}>
                    {({ open }) => (
                      <>
                        <div className="mt-1 relative">
                          <Listbox.Button className="h-10 relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="flex items-center">
                              <img
                                alt="ERC20 Icon"
                                src="https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
                                className="flex-shrink-0 h-5 w-5 rounded-full"
                              />
                              <span className="ml-3 block truncate">
                                {currency?.name}
                              </span>
                            </span>
                            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {currencies.map((currency) => (
                                <Listbox.Option
                                  key={currency.address}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-blue-600'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9',
                                    )
                                  }
                                  value={currency}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex items-center">
                                        <img
                                          src={currency.icon}
                                          alt=""
                                          className="flex-shrink-0 h-6 w-6 rounded-full"
                                        />
                                        <span
                                          className={classNames(
                                            selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                            'ml-3 block truncate',
                                          )}
                                        >
                                          {currency.name}
                                        </span>
                                      </div>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-blue-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  <p className="mt-2 text-gray-600 text-sm">
                    All payments, including claims, premiums and fees will be
                    paid using the selected ERC20 token. Learn more about it{' '}
                    <span className="text-blue-500 cursor-pointer">here</span>
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Amount
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-1">
                  <input
                    type="text"
                    //@ts-ignore
                    ref={amountRef}
                    className="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                  />
                  <p className="mt-2 text-gray-600 text-sm">
                    The amount of shares issued for your selected risk. The
                    amount is denominated in ERC20 tokens and will later be
                    collateralized by the investors, in return for a premium.
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="inception"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Inception
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-1">
                  <DatePicker
                    customInput={
                      <input
                        type="text"
                        className="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    }
                    selected={inception}
                    onChange={(date) => {
                      if (date) setInception(date)
                    }}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="inception"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Maturity
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-1">
                  <DatePicker
                    customInput={
                      <input
                        type="text"
                        className="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                      />
                    }
                    selected={maturity}
                    onChange={(date) => {
                      if (date) setMaturity(date)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-10">
          <TxProgressComponent progress={progress} txHash={txHash} />
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Back
            </button>
            <button
              onClick={submit}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ryskex hover:bg-ryskex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ryskex"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateScreen
