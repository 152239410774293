const toast = console.log

// @ts-ignore
const notifications = () => (next) => (action) => {
  if (action.type === 'user/authenticate/rejected') {
    toast({
      title: 'Authentication failed',
      description: 'Please check email address and password.',
      status: 'error',
    })
  } else if (action.type === 'user/register/fulfilled') {
    toast({
      title: 'Registration successful',
      description: 'You can now login with your email and password.',
      status: 'success',
    })
  } else if (action.type === 'user/register/rejected') {
    toast({
      title: 'Registration failed',
      description: 'This email address already exists.',
      status: 'error',
    })
  }

  return next(action)
}

export default notifications
