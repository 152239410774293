import React from 'react'
import RiskTaker from './risk-taker'

const RiskTakersScreen: React.FunctionComponent = () => {
  return (
    <div className="m-4">
      <div className="max-w-8xl mx-auto mb-6">
        <div className="md:flex md:items-center md:justify-between md:space-x-5">
          <div className="flex items-center space-x-5">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Risk Taker Portfolio
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Shows a portfolio selection of committed risk takers, their
                preferred perils and expectations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <RiskTaker
          name="Twelve Capital"
          locations="Zurich, London, Munich"
          fundSize="USD 3.8bn"
          investmentSize="USD 5mn at 2% - 6% expected loss"
          investmentGeography="Global"
          about="Twelve Capital is an independent investment manager
          specialising in insurance investments for institutional
          clients. The company is also a leading provider of capital to
          the insurance and reinsurance industry."
          perils={['Windstorm / Hurricane', 'Earthquake', 'Drought', 'Flood']}
        />
        <RiskTaker
          name="Axis Capital"
          locations="17 offices globally"
          fundSize="USD 27.4bn"
          investmentSize="USD 25-50mn"
          investmentGeography="Global"
          about="AXIS is an international specialty insurer and reinsurer. They have a global platform, expertise across diverse lines of business and products, and a commitment to delivering unparalleled customer service."
          perils={['Cyber - Ransomware', 'Cyber - Reputation']}
        />
        <RiskTaker
          name="Dynamo Insurance Company"
          locations="Conneticut, United States"
          fundSize=""
          investmentSize="USD 2.5-10mn"
          investmentGeography="United States / Europe"
          about="Dynamo Insurance Company Inc. is the captive company of Cummins Inc., a global corporate, specialized in diesel and alternative fuel engines."
          perils={[
            'Property & Casualty',
            'Employee Benefits',
            'Natural Catastrophes',
          ]}
        />
        <RiskTaker
          name="Munich Re"
          locations="Global"
          fundSize="USD 31bn"
          investmentSize="USD 10-50mn at 2% - 6% expected loss"
          investmentGeography="Global"
          about="Munich Re Group is one of the world's leading risk managers. Founded in 1880 in Germany, the company is now active on a global scale. Munich Re has held the number 1 spot in the Top 50 of global reinsurance groups since 2010, except for 2017."
          perils={['Pandemic']}
        />
      </div>
    </div>
  )
}

export default RiskTakersScreen
