import dayjs from 'dayjs'
import { ethers } from 'ethers'
import React, { useEffect, useState } from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useNavigate } from 'react-router'
import { routes } from 'routes'
import { SmartBond } from 'screens/assets/asset.screen'
import { fetchSmartBonds } from 'screens/assets/asset.service'

const MarketplaceScreen: React.FunctionComponent = () => {
  const navigate = useNavigate()

  const [smartBonds, setSmartBonds] = useState<SmartBond[]>([])

  const getSmartBonds = async () => {
    const res = await fetchSmartBonds()

    setSmartBonds(res.data.payload)
  }

  useEffect(() => {
    getSmartBonds()
  }, [])

  return (
    <div className="m-4">
      <div className="md:flex md:items-center md:justify-between md:space-x-5">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Marketplace</h1>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button
            type="button"
            onClick={getSmartBonds}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ryskex"
          >
            Refresh
          </button>
          <button
            type="button"
            onClick={() => navigate('/assets/create')}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-ryskex hover:bg-ryskex/95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ryskex"
          >
            Issue smart bond
          </button>
        </div>
      </div>

      <div className="mt-6 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sponsor
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Inception
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Maturity
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {smartBonds.map((smartBond) => {
                    let status: React.ReactNode = null
                    if (smartBond.status === 1) {
                      status = (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Trading
                        </span>
                      )
                    } else if (smartBond.status === 2) {
                      status = (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          Defaulted
                        </span>
                      )
                    }

                    return (
                      <tr key={smartBond.signer.address}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                              <Jazzicon
                                diameter={38}
                                seed={jsNumberForAddress(
                                  smartBond.signer.address,
                                )}
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {smartBond.signer.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {smartBond.signer.address}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {smartBond.termsheet.title}
                          </div>
                          <div className="text-sm text-gray-500">
                            {Intl.NumberFormat('en').format(
                              parseInt(
                                ethers.utils.formatUnits(
                                  smartBond.amount,
                                  smartBond.currency.decimals,
                                ),
                              ),
                            )}{' '}
                            {smartBond.currency.symbol}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {status}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {dayjs(smartBond.inception).format('MMM YYYY')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {dayjs(smartBond.maturity).format('MMM YYYY')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() =>
                              navigate(routes.getAssetRoute(smartBond.id))
                            }
                            className="text-ryskex hover:text-ryskex/95"
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketplaceScreen
