// @ts-nocheck
export const SERVER_URL = window.__ENV__
  ? window.__ENV__.REACT_APP_SERVER_URL
  : process.env.REACT_APP_SERVER_URL

export const REGISTER_ENDPOINT = '/api/v1/signers/create'
export const SECRET_ENDPOINT = '/api/v1/signers/secret'
export const AUTHENTICATE_ENDPOINT = '/api/v1/signers/authenticate'
export const SMART_BONDS_ENDPOINT = '/api/v1/smartbonds'
export const CURRENCIES_ENDPOINT = '/api/v1/currencies'
export const TERM_SHEETS_ENDPOINT = '/api/v1/term-sheets'
