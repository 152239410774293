import jwtd from 'jwt-decode'

export interface DecodedJWT {
  id: string
  iat: number
  exp: number
}

class JWT {
  private token: string | null = null

  save = async (token: string): Promise<void> => {
    await localStorage.setItem('jwt', token)
    this.token = token
  }

  read = async (): Promise<string | null> => {
    if (this.token) return this.token

    const token = await localStorage.getItem('jwt')
    this.token = token

    return token
  }

  decode = async () => {
    const token = await this.read()
    if (!token) return null

    return jwtd(token) as DecodedJWT
  }

  isValid = async () => {
    const decoded = await this.decode()
    if (!decoded) return false

    return decoded && Date.now() < decoded.exp * 1000
  }

  delete = async () => {
    await localStorage.removeItem('jwt')
    this.token = null
  }
}

export default new JWT()
