import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { SERVER_URL } from 'app/server.config'
import token from 'app/server.jwt'

export const request = async (
  config: AxiosRequestConfig,
): Promise<AxiosResponse> => {
  const jwt: string | null = await token.read()

  const headers = {}
  if (jwt) Object.assign(headers, { Authorization: 'Bearer ' + jwt })

  return axios({
    baseURL: SERVER_URL,
    timeout: 12000,
    headers,
    validateStatus: (status: number) => status >= 200 && status < 300,
    ...config,
  })
}
