import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import jwtd from 'jwt-decode'

import jwt, { DecodedJWT } from 'app/server.jwt'
import { RootState } from 'app/redux.store'
import { UserState, Signer } from './user.types'
import * as service from './user.service'
import { getSigner } from 'lib/ethereum'

export const initialState: UserState = {
  isLoading: false,
  signer: undefined,
  token: undefined,
  etherBalance: 0,
  tokenBalance: 0,
}

export const authenticate = createAsyncThunk(
  'user/authenticate',
  async (signature: string) => {
    const signer = await getSigner()
    const address = await signer.getAddress()

    const { data } = await service.authenticate(address, signature)
    await jwt.save(data.token)
    return data
  },
)

export const getEtherBalance = createAsyncThunk(
  'user/getEtherBalance',
  service.fetchEtherBalance,
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(authenticate.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        const { signer, token } = action.payload

        state.isLoading = false
        state.signer = signer
        state.token = jwtd(token)
      })
      .addCase(getEtherBalance.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getEtherBalance.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getEtherBalance.fulfilled, (state, action) => {
        state.isLoading = false
        state.etherBalance = action.payload
      })
  },
})

export const selectIsLoading = (state: RootState): boolean =>
  state.user.isLoading

export const selectSigner = (state: RootState): Signer | undefined =>
  state.user.signer

export const selectToken = (state: RootState): DecodedJWT | undefined =>
  state.user.token

export const selectSignerAddress = (state: RootState): string | undefined =>
  state.user.signer?.address

export const selectIsAuthenticated = createSelector(
  selectSignerAddress,
  selectToken,
  (signer: string | undefined, token: DecodedJWT | undefined) =>
    signer && token && Date.now() < token.exp * 1000,
)

export const selectEtherBalance = (state: RootState): number =>
  state.user.etherBalance

export default userSlice.reducer
