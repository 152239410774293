import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

import { getMarketplace } from 'lib/ethereum'
import TxProgressComponent from './tx-progress.component'
import { SmartBond } from './asset.screen'

export interface Config {
  isOpen: boolean
  params?: {
    smartBond: SmartBond
  }
}

interface Props {
  config?: Config
  close: () => void
}

const RecoverCapital: React.FunctionComponent<Props> = (props) => {
  const [txHash, setTxHash] = useState('')
  const [progress, setProgress] = useState(0)

  const recoverCapital = async () => {
    setProgress(1)

    if (!props.config?.params?.smartBond) return

    const smartBondId = props.config.params.smartBond.id

    const marketplace = await getMarketplace()
    const response = await marketplace.refund(smartBondId)

    setProgress(64)

    const tx = await response.wait()

    setProgress(100)
    setTxHash(tx.transactionHash)
  }

  useEffect(() => {
    setTxHash('')
    setProgress(0)
  }, [props.config])

  return (
    <Transition.Root show={props.config?.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={props.close}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-ryskex p-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Recover capital
                          </Dialog.Title>
                          <p className="text-sm text-gray-100">
                            As the smart bond matured or defaulted, liquidity
                            can now ne withdrawn.
                          </p>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-100 hover:text-gray-200"
                            onClick={props.close}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 p-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-900">
                            Information
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <p className="text-sm">
                            This will liquidate all your shares, to transfer
                            back remaining capital into your wallet.
                          </p>
                        </div>
                      </div>

                      <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5">
                        <div>
                          <label className="block text-sm font-medium text-gray-900">
                            Next steps
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <p className="text-sm">
                            There are no further actions required for this case.
                            You are free to reinvest your capital.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6">
                    <TxProgressComponent progress={progress} txHash={txHash} />
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 p-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={props.close}
                      >
                        Close
                      </button>
                      <button
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault()
                          recoverCapital()
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-ryskex py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-ryskex/95 focus:outline-none focus:ring-2 focus:ring-ryskex/95 focus:ring-offset-2"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default RecoverCapital
