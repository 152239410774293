import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'app/redux.hooks'
import * as userRx from 'screens/user/user.redux'
import SignInScreen from 'screens/user/signin.screen'
import MarketplaceScreen from 'screens/marketplace/marketplace.screen'
import CoreLayout from 'screens/core/core.layout'
import AssetScreen from 'screens/assets/asset.screen'
import CreateScreen from 'screens/assets/create.screen'
import UserProfileScreen from 'screens/user/user-profile.screen'
import RiskTakersScreen from 'screens/risk-takers/risk-takers.screen'
import DashboardScreen from 'screens/dashboard/dashboard.screen'

export const routes = {
  getBaseRoute: () => '/',
  getUserProfileRoute: () => '/profile',
  getAssetRoute: (smartBondId: number) => '/assets/' + smartBondId,
  getCreateRoute: () => '/assets/create',
  getRyskTakersRoute: () => '/rysk-takers',
  getDashboardRoute: () => '/dashboard',
}

const AppRoutes = (): JSX.Element | null => {
  const dispatch = useAppDispatch()

  const isAuthenticated = useAppSelector(userRx.selectIsAuthenticated)

  useEffect(() => {
    if (isAuthenticated) dispatch(userRx.getEtherBalance())
  }, [isAuthenticated, dispatch])

  return isAuthenticated ? (
    <Router>
      <React.Suspense fallback={null}>
        <CoreLayout
          render={() => (
            <Routes>
              <Route
                path={routes.getBaseRoute()}
                element={<MarketplaceScreen />}
              />
              <Route path="/profile" element={<UserProfileScreen />} />
              <Route path="/assets/:smartBondId" element={<AssetScreen />} />
              <Route path="/assets/create" element={<CreateScreen />} />
              <Route path="/rysk-takers" element={<RiskTakersScreen />} />
              <Route path="/dashboard" element={<DashboardScreen />} />
              <Route
                path="*"
                element={<Navigate replace to={routes.getBaseRoute()} />}
              />
            </Routes>
          )}
        />
      </React.Suspense>
    </Router>
  ) : (
    <Router>
      <React.Suspense fallback={null}>
        <Routes>
          <Route path={routes.getBaseRoute()} element={<SignInScreen />} />
          <Route
            path="*"
            element={<Navigate replace to={routes.getBaseRoute()} />}
          />
        </Routes>
      </React.Suspense>
    </Router>
  )
}

export default AppRoutes
