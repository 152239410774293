import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import jwt from 'app/server.jwt'
import coreReducer from 'screens/core/core.redux'
import userReducer from 'screens/user/user.redux'
import notifications from './notifications.middleware'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user'],
}

const appReducer = combineReducers({
  core: coreReducer,
  user: userReducer,
})

// eslint-disable-next-line
const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
    jwt.delete()
  }

  return appReducer(state, action)
}

const persistedRootReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(notifications),
})
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
