import React, { useEffect, useState } from 'react'
import { PaperClipIcon, CheckCircleIcon } from '@heroicons/react/solid'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { getMarketplace } from 'lib/ethereum'
import { useNavigate, useParams } from 'react-router'
import { fetchOrders, fetchPayouts, fetchSmartBond } from './asset.service'
import { Signer } from 'screens/user/user.types'
import StepsComponent from './steps.component'
import OrderPlaceComponent, {
  Config as OPConfig,
} from './order-place.component'
import OrderFillComponent, { Config as OFConfig } from './order-fill.component'
import OrderCancelComponent, {
  Config as OCConfig,
} from './order-cancel.component'
import RecoverCapitalComponent, {
  Config as RCConfig,
} from './recover-capital.component'
import { useAppSelector } from 'app/redux.hooks'
import * as userRx from 'screens/user/user.redux'
import { BigNumber, ethers } from 'ethers'
import { routes } from 'routes'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export interface Termsheet {
  hash: string
  title: string
  uri: string
  description: string
}

export interface Currency {
  address: string
  name: string
  symbol: string
  decimals: number
  icon: string
}

export interface CSmartBond {
  signer: string
  termsheet: string
  currency: string
  amount: string
  claimed: string
  filled: string
  inception: string
  maturity: string
  status: number
}

export interface SmartBond {
  id: number
  signer: Signer
  termsheet: Termsheet
  currency: Currency
  amount: string
  inception: string
  maturity: string
  status: number
  created_at: string
}

export interface Order {
  smartBond: number
  id: number
  signer: Signer
  side: number
  amount: string
  filled: string
  premium: number
}

export interface Payout {
  id: number
  amount: string
  created_at: string
}

export const initialSmartBond: SmartBond = {
  id: 0,
  signer: {
    address: '',
    name: '',
  },
  termsheet: {
    hash: '',
    title: '',
    uri: '',
    description: '',
  },
  currency: {
    address: '',
    name: '',
    symbol: '',
    decimals: 18,
    icon: '',
  },
  amount: '0',
  inception: '',
  maturity: '',
  status: 0,
  created_at: '',
}

const initialCSmartBond: CSmartBond = {
  amount: '0',
  claimed: '0',
  currency: '',
  filled: '0',
  inception: '',
  maturity: '',
  signer: '',
  status: 0,
  termsheet: '',
}

const AssetScreen: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [smartBond, setSmartBond] = useState<SmartBond>(initialSmartBond)
  const [cSmartBond, setCSmartBond] = useState<CSmartBond>(initialCSmartBond)
  const [orders, setOrders] = useState<Order[]>([])
  const [payouts, setPayouts] = useState<Payout[]>([])
  const [balance, setBalance] = useState('0')
  const [escrowed, setEscrowed] = useState('0')

  const [orderPlaceOpen, setOrderPlaceOpen] = useState<OPConfig>({
    isOpen: false,
  })
  const [orderFillOpen, setOrderFillOpen] = useState<OFConfig>({
    isOpen: false,
  })
  const [orderCancelOpen, setOrderCancelOpen] = useState<OCConfig>({
    isOpen: false,
  })
  const [recoverCapitalOpen, setRecoverCapitalOpen] = useState<RCConfig>({
    isOpen: false,
  })

  const signerAddress = useAppSelector(userRx.selectSignerAddress)

  const params = useParams<{ smartBondId: string }>()
  const isTrading = smartBond.status === 1
  const isDefaulted = smartBond.status === 2
  const smartBondId = parseInt(params.smartBondId || '0')
  const f_amount = parseInt(
    ethers.utils.formatUnits(smartBond.amount, smartBond.currency.decimals),
  )
  const f_filled = parseInt(
    ethers.utils.formatUnits(cSmartBond.filled, smartBond.currency.decimals),
  )
  const f_claimed = parseInt(
    ethers.utils.formatUnits(cSmartBond.claimed, smartBond.currency.decimals),
  )
  const f_escrowed = parseInt(
    ethers.utils.formatUnits(escrowed, smartBond.currency.decimals),
  )
  const f_balance = parseInt(
    ethers.utils.formatUnits(balance, smartBond.currency.decimals),
  )
  const sellOrders = orders
    .filter((v) => v.side === 0)
    .sort((a, b) => b.premium - a.premium)
  const buyOrders = orders
    .filter((v) => v.side === 1)
    .sort((a, b) => a.premium - b.premium)

  // const isIncepted = dayjs(smartBond.inception).isBefore(dayjs())
  const isMatured = dayjs(smartBond.maturity).isBefore(dayjs())
  const isSignerSponsor = smartBond.signer.address === signerAddress
  const isSignerInvested = f_balance > 0
  const hasInvestors = f_filled > 0
  const hasOrders = orders.length > 0
  const isExhausted = f_claimed === f_filled

  let holdings = 0
  if (f_balance) {
    holdings = f_balance
  }
  if (!f_balance) {
    holdings = 0
  }
  if (f_balance && f_claimed) {
    holdings = (1 - f_claimed / f_amount) * f_balance
  }

  const getCSmartBond = async () => {
    const marketplace = await getMarketplace()
    //const response = await marketplace.getSmartBond(smartBondId)
    const response = await marketplace.smartBonds(smartBondId)

    const {
      amount,
      liquidated,
      currency,
      filled,
      inception,
      maturity,
      signer,
      status,
      termsheet,
    } = response

    setCSmartBond({
      amount: amount.toString(),
      claimed: liquidated.toString(),
      currency,
      filled: filled.toString(),
      inception: inception.toString(),
      maturity: maturity.toString(),
      signer,
      status,
      termsheet,
    })
  }

  const getSmartBond = async () => {
    const {
      data: { payload },
    } = await fetchSmartBond({ id: smartBondId })

    setSmartBond(payload)
  }

  const getOrders = async () => {
    const {
      data: { payload },
    } = await fetchOrders({ id: smartBondId })

    setOrders(payload)
  }

  const getPayouts = async () => {
    const {
      data: { payload },
    } = await fetchPayouts({ id: smartBondId })

    setPayouts(payload)
  }

  const getAccountState = async () => {
    const marketplace = await getMarketplace()
    const address = await marketplace.signer.getAddress()
    const response = await marketplace.getAccountState(smartBondId, address)

    setBalance(response.balance.toString())
    setEscrowed(response.escrowed.toString())
  }

  const refresh = () => {
    getCSmartBond()
    getAccountState()
    getSmartBond()
    getOrders()
    getPayouts()
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <>
      <OrderPlaceComponent
        config={orderPlaceOpen}
        close={() => setOrderPlaceOpen({ isOpen: false })}
      />
      <OrderFillComponent
        config={orderFillOpen}
        close={() => setOrderFillOpen({ isOpen: false })}
      />
      <OrderCancelComponent
        config={orderCancelOpen}
        close={() => setOrderCancelOpen({ isOpen: false })}
      />
      <RecoverCapitalComponent
        config={recoverCapitalOpen}
        close={() => setRecoverCapitalOpen({ isOpen: false })}
      />
      <StepsComponent
        smartBondId={smartBondId}
        isTrading={isTrading}
        isDefaulted={isDefaulted}
        issuedAt={smartBond.created_at}
        inception={smartBond.inception}
        maturity={smartBond.maturity}
      />

      <div className="m-4">
        <div className="md:flex md:items-center md:justify-between md:space-x-5">
          <div className="flex items-center space-x-5">
            <div>
              <div className="flex row items-center">
                <h1 className="text-2xl font-bold text-gray-900">
                  {smartBond.termsheet.title}
                </h1>
                {smartBond.termsheet.hash ===
                '0xef8a80cfcde7509b9d35dad9a31c071247f816e6a2c791b2bd76220a6a941f97' ? (
                  <a
                    onClick={() => navigate(routes.getDashboardRoute())}
                    className="mt-1 text-sm font-medium text-blue-600 cursor-pointer hover:text-blue-700"
                  >
                    &nbsp; Open Dashboard
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                ) : null}
              </div>

              <p className="text-sm font-medium text-gray-500">
                Issued{' '}
                <time dateTime="2020-08-25">
                  {dayjs(smartBond.created_at).format('MMMM DD, YYYY')}
                </time>{' '}
                by {smartBond.signer.name}
              </p>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button
              type="button"
              onClick={refresh}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ryskex"
            >
              Refresh
            </button>

            {isTrading ? (
              <button
                type="button"
                onClick={() =>
                  setOrderPlaceOpen({ isOpen: true, params: { smartBond } })
                }
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-ryskex hover:bg-ryskex/95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ryskex"
              >
                Place an order
              </button>
            ) : null}

            {(isSignerInvested && isMatured) ||
            (isDefaulted &&
              !isSignerSponsor &&
              isSignerInvested &&
              !isExhausted) ? (
              <button
                type="button"
                onClick={() =>
                  setRecoverCapitalOpen({
                    isOpen: true,
                    params: { smartBond },
                  })
                }
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-ryskex hover:bg-ryskex/95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ryskex"
              >
                Recover capital
              </button>
            ) : null}
          </div>
        </div>

        <div className="mt-5 max-w-8xl mx-auto grid grid-cols-3 gap-5 grid-flow-col-dense">
          <div className="space-y-6 col-start-1 col-span-2">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  General Information
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Issuer details, triggering parameters and risk definition.
                </p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Issued shares
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {Intl.NumberFormat('en').format(f_amount)}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Term</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dayjs(smartBond.inception).format('MMMM DD, YYYY')} -{' '}
                      {dayjs(smartBond.maturity).format('MMMM DD, YYYY')}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Currency
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {smartBond.currency.symbol} ({smartBond.currency.name})
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Claim adjustment
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">Algorithmic</dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Description
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {smartBond.termsheet.description}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Attachments
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <PaperClipIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {smartBond.termsheet.hash}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <a
                              href={smartBond.termsheet.uri}
                              target="_blank"
                              rel="noreferrer"
                              className="font-medium text-ryskex hover:text-ryskex"
                            >
                              Download
                            </a>
                          </div>
                        </li>
                      </ul>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-5">
            <div className="col-start-1 col-span-3 bg-white shadow overflow-hidden rounded-lg">
              <div className="px-6 py-5">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Capitalization
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Shows how many shares of this risk are collateralized
                </p>
              </div>
              <div className="border-t border-gray-200 px-6 py-5">
                {!isDefaulted ? (
                  <>
                    <div className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl font-semibold ">
                        {Intl.NumberFormat('en').format(f_filled)}
                        <span className="ml-2 text-sm font-medium text-gray-500">
                          {smartBond.currency.symbol} collateralized
                        </span>
                      </div>

                      <div className="bg-blue-100 text-blue-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                        {((f_filled / f_amount) * 100).toFixed(2)}%
                      </div>
                    </div>

                    <div className="mt-3 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
                      <div
                        className="bg-ryskex h-1 rounded-full"
                        style={{
                          width: (f_filled / f_amount) * 100 + '%',
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl text-red-600 font-semibold ">
                        {Intl.NumberFormat('en').format(
                          (f_claimed / f_amount) * f_amount,
                        )}
                        <span className="ml-2 text-sm font-medium text-gray-500">
                          shares liquidated
                        </span>
                      </div>

                      <div className="bg-red-100 text-red-600 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                        {((f_claimed / f_amount) * 100).toFixed(2)}%
                      </div>
                    </div>

                    <div className="mt-3 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
                      <div
                        className="bg-red-600 h-1 rounded-full"
                        style={{
                          width: (f_claimed / f_amount) * 100 + '%',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-start-1 col-span-3 bg-white shadow overflow-hidden rounded-lg">
              <div className="px-6 py-5">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Your holdings
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  The number of collateralized risk shares you are holding
                </p>
              </div>
              <div className="border-t border-gray-200 px-6 py-5">
                <div className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold">
                    {Intl.NumberFormat('en').format(
                      //(f_claimed / f_amount) * f_balance,
                      holdings,
                    )}
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      {smartBond.currency.symbol} of{' '}
                      {Intl.NumberFormat('en', {
                        notation: 'compact',
                      }).format(f_balance)}
                    </span>
                  </div>

                  <div className="bg-green-100 text-green-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                    {((holdings / f_balance) * 100 || 0).toFixed(2)}%
                  </div>
                </div>

                <div className="mt-3 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
                  <div
                    className="bg-green-600 h-1 rounded-full"
                    style={{
                      width: ((holdings / f_balance) * 100 || 0) + '%',
                    }}
                  />
                </div>

                {f_escrowed > 0 && !isDefaulted ? (
                  <>
                    <div className="mt-1 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
                      <div
                        className="bg-gray-500 h-1 rounded-full"
                        style={{
                          width:
                            f_balance > 0
                              ? (f_escrowed / f_balance) * 100 + '%'
                              : '0%',
                        }}
                      />
                    </div>

                    <div className="mt-3 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-sm font-semibold text-gray-500">
                        {Intl.NumberFormat('en').format(f_escrowed)}
                        <span className="ml-2 text-sm font-medium text-gray-500">
                          shares escrowed (sell orders)
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/*<div className="col-start-1 col-span-3 bg-white shadow overflow-hidden rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 sm:col-span-2">
                      Calculation agent
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                      BlockART Institute
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 sm:col-span-2">
                      Probability of attachment
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                      3.14%
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 sm:col-span-2">
                      Probability of exhaustion
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                      0.21%
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 sm:col-span-2">
                      Expected loss
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                      1.85%
                    </dd>
                  </div>
                </dl>
              </div>
            </div>*/}
          </div>
        </div>

        {/*hasPayoutRequestsOrPayouts ? (
          <>
            <div className="mt-12 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Payout requests
              </h3>
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <button
                  type="button"
                  onClick={getPayouts}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ryskex"
                >
                  Refresh
                </button>
              </div>
            </div>

            <div className="mt-5 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Time of request
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Amount to liquidate
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Oracle approvals
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Oracle rejections
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {payouts.map((payout) => {
                    let status = null
                    if (payout.status === 0)
                      status = (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                          Pending
                        </span>
                      )
                    if (payout.status === 1)
                      status = (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Approved
                        </span>
                      )
                    if (payout.status === 2)
                      status = (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          Rejected
                        </span>
                      )

                    return (
                      <tr key={payout.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {payout.id}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {dayjs(payout.created_at).format('LLL')}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {Intl.NumberFormat('en').format(
                              parseInt(
                                ethers.utils.formatUnits(
                                  payout.amount,
                                  smartBond.currency.decimals,
                                ),
                              ),
                            )}
                          </span>
                          &nbsp;
                          <span className="text-xs font-medium text-gray-500">
                            shares
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {payout.approvals}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {payout.rejections}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          {status}
                        </td>

                        {isSignerOracle && payout.status === 0 ? (
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              onClick={() =>
                                setManagePayoutOpen({
                                  isOpen: true,
                                  params: {
                                    smartBond,
                                    payout,
                                  },
                                })
                              }
                              className="text-ryskex hover:text-blue-900"
                            >
                              Manage
                            </button>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : null*/}

        {hasOrders ? (
          <>
            <div className="mt-12 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Order book
              </h3>
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <button
                  type="button"
                  onClick={getOrders}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ryskex"
                >
                  Refresh
                </button>
              </div>
            </div>

            <div className="mt-5 max-w-8xl mx-auto grid grid-cols-2 gap-2">
              <div className="col-start-1 col-span-1 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Sell order
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Open shares
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Ask
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sellOrders.map((order) => (
                      <tr
                        key={order.id}
                        className="cursor-pointer"
                        onClick={
                          order.signer.address === signerAddress
                            ? () =>
                                setOrderCancelOpen({
                                  isOpen: true,
                                  params: {
                                    smartBond,
                                    order,
                                  },
                                })
                            : () =>
                                setOrderFillOpen({
                                  isOpen: true,
                                  params: {
                                    smartBond,
                                    order,
                                  },
                                })
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="mt-2 flex items-center text-sm font-medium text-gray-900">
                            <span className="text-sm font-medium text-gray-900">
                              {Intl.NumberFormat('en').format(
                                parseInt(
                                  ethers.utils.formatUnits(
                                    order.amount,
                                    smartBond.currency.decimals,
                                  ),
                                ),
                              )}
                            </span>
                            <div className="text-red-500 hidden" />
                          </div>
                          <div
                            className={`text-xs text-${
                              order.signer.address === signerAddress
                                ? 'red'
                                : 'gray'
                            }-500`}
                          >
                            {order.signer.name}
                          </div>
                        </td>

                        <td className="px-6 py-4 text-center whitespace-nowrap">
                          <div className="mt-3 mb-1 w-32 bg-gray-300 rounded-full h-1 dark:bg-gray-700">
                            <div
                              className="bg-red-500 h-1 rounded-full"
                              style={{
                                width:
                                  BigNumber.from(order.amount)
                                    .sub(order.filled)
                                    .mul(100)
                                    .div(order.amount)
                                    .toString() + '%',
                              }}
                            />
                          </div>
                        </td>

                        <td className="px-6 py-4 text-right whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {Intl.NumberFormat('en').format(
                              parseInt(
                                ethers.utils.formatUnits(
                                  order.amount,
                                  smartBond.currency.decimals,
                                ),
                              ) -
                                parseInt(
                                  ethers.utils.formatUnits(
                                    order.filled,
                                    smartBond.currency.decimals,
                                  ),
                                ),
                            )}
                          </span>
                        </td>

                        <td className="px-6 py-4 text-right whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {order.premium}
                          </span>
                          &nbsp;
                          <span className="text-xs font-medium text-gray-500">
                            BPS
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-start-2 col-span-1 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Bid
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Open shares
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Buy order
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {buyOrders.map((order) => (
                      <tr
                        key={order.id}
                        className="cursor-pointer"
                        onClick={
                          order.signer.address === signerAddress
                            ? () =>
                                setOrderCancelOpen({
                                  isOpen: true,
                                  params: {
                                    smartBond,
                                    order,
                                  },
                                })
                            : () =>
                                setOrderFillOpen({
                                  isOpen: true,
                                  params: {
                                    smartBond,
                                    order,
                                  },
                                })
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {order.premium}
                          </span>
                          &nbsp;
                          <span className="text-xs font-medium text-gray-500">
                            BPS
                          </span>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {Intl.NumberFormat('en').format(
                              parseInt(
                                ethers.utils.formatUnits(
                                  order.amount,
                                  smartBond.currency.decimals,
                                ),
                              ) -
                                parseInt(
                                  ethers.utils.formatUnits(
                                    order.filled,
                                    smartBond.currency.decimals,
                                  ),
                                ),
                            )}
                          </span>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="mt-3 mb-1 w-32 bg-gray-300 rounded-full h-1 dark:bg-gray-700">
                            <div
                              className="bg-ryskex h-1 rounded-full"
                              style={{
                                width:
                                  BigNumber.from(order.amount)
                                    .sub(order.filled)
                                    .mul(100)
                                    .div(order.amount)
                                    .toString() + '%',
                              }}
                            />
                          </div>
                        </td>

                        <td className="px-6 py-4 text-right whitespace-nowrap">
                          <div className="mt-2 text-sm font-medium text-gray-900">
                            <span className="text-sm font-medium text-gray-900">
                              {Intl.NumberFormat('en').format(
                                parseInt(
                                  ethers.utils.formatUnits(
                                    order.amount,
                                    smartBond.currency.decimals,
                                  ),
                                ),
                              )}
                            </span>
                          </div>
                          <div
                            className={`text-xs text-${
                              order.signer.address === signerAddress
                                ? 'blue'
                                : 'gray'
                            }-500`}
                          >
                            {order.signer.name}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}

        {/*<div className="mt-12 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Order history
          </h3>
          <div className="mt-3 flex sm:mt-0 sm:ml-4">
            <button
              type="button"
              onClick={getOrders}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ryskex"
            >
              Refresh
            </button>
          </div>
        </div>

        <div className="mt-5">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Organization
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Shares
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Percent of Limit
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {applications.map((order) => (
                <tr key={order.address}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      {order.image}
                      <div className="ml-4">
                        <div className="mt-2 flex items-center text-sm font-medium text-gray-900">
                          {order.name}
                        </div>
                        <div className="text-xs text-gray-500">
                          {order.address}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-900">
                      {Intl.NumberFormat('en').format(order.shares)}
                    </span>
                    &nbsp;
                    <span className="text-xs font-medium text-gray-500">
                      {smartBond.currency.symbol}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-900">
                      {order.premium}
                    </span>
                    &nbsp;
                    <span className="text-xs font-medium text-gray-500">
                      BPS
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>*/}
      </div>
    </>
  )
}

export default AssetScreen
